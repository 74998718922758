import request from '@/utils/requestV2'
const qs = require('qs')

// 给销售分配网格
export function addUser (data) {
  return request({
    url: '/ooh-sf/cell/adduser',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取城市可分配网格
export function getCityCell (data) {
  return request({
    url: '/ooh-sf/cell/getcitycell',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 删除销售网格
export function deleteUser (data) {
  return request({
    url: '/ooh-sf/cell/deleteuser',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取当前登录用户小微站点集合。按名称分组
export function getStationList (data) {
  return request({
    url: '/ooh-sf/cell/getstationlist',
    method: 'post',
    data: qs.stringify(data)
  })
}
