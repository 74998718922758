<template>
  <div id="map_box">
    <div id="map_search">
      <Row :gutter="8">
        <i-col span="15" style="margin-bottom:15px;">
          <Select
            class="search_option m-r-5"
            v-model="curType"
            type="button"
            size="small"
            @on-change="handleChangeType"
          >
            <Option
              v-for="item in typeArray"
              :key="'type_'+item.value"
              :value="item.value"
            >{{item.name}}</Option>
          </Select>
          <Select
            class="search_option m-r-5"
            :clearable="true"
            v-model="assetId"
            type="button"
            size="small"
            placeholder="请选择资产"
            @on-change="handleChangeAsset"
          >
            <Option
              v-for="item in assetArray"
              :key="'asset_'+item.id"
              :value="item.id"
            >{{item.name}}</Option>
          </Select>
          <Select
            class="search_option m-r-5"
            :clearable="true"
            v-model="stationId"
            type="button"
            size="small"
            placeholder="请选择站点"
            @on-change="handleChangeStation"
          >
            <Option
              v-for="item in stationArray"
              :key="'station_'+item.id"
              :value="item.id"
            >{{item.name}}</Option>
          </Select>
          <Button size="small" class="search_option m-r-5"   type="success" @click="confirmDownload">下载商家列表</Button>
        </i-col>
        <i-col span="9">
          <div id="color-info">
            <h4 class="text-center m-b-10">图例说明</h4>
            <div class="hex_nd">
              <div class="hex_nd_bj"></div>
              <ul>
                <li class="text-left">0%</li>
                <li class="text-left">25%</li>
                <li class="text-center">50%</li>
                <li class="text-right">75%</li>
                <li class="text-right">100%</li>
              </ul>
              <div style="clear:both"></div>
            </div>
            <Row
              v-if="selectedUserId"
              :gutter="8"
              class="m-t-10"
            >
              <i-col span="6">
                <img
                  src="~@/assets/images/hex_blank.png"
                  width="14"
                  height="14"
                />
                <span>无数据</span>
              </i-col>
              <i-col span="6">
                <img
                  src="~@/assets/images/hex_grey.png"
                  width="14"
                  height="14"
                />
                <span>不可分配</span>
              </i-col>
              <i-col span="6">
                <img
                  src="~@/assets/images/hex_green.png"
                  width="14"
                  height="14"
                />
                <span>已拥有</span>
              </i-col>
              <i-col span="6">
                <img
                  src="~@/assets/images/hex_blue.png"
                  width="14"
                  height="14"
                />
                <span>已选中</span>
              </i-col>
            </Row>
          </div>
        </i-col>
      </Row>

    </div>
    <!-- <Row :gutter="2">
        <i-col span="4">
          <Select
            v-model="curType"
            type="button"
            size="small"
            @on-change="handleChangeType"
          >
            <Option
              v-for="item in typeArray"
              :key="'type_'+item.value"
              :label="item.value"
            >{{item.name}}</Option>
          </Select>
        </i-col>
        <i-col span="6">
          <Row :gutter="8">
            <i-col span="10">
              <RadioGroup
                v-model="curUserType"
                type="button"
                size="small"
                @on-change="handleChangeUserType"
              >
                <Radio
                  v-for="item in userTypeArray"
                  :key="'type_'+item.value"
                  :label="item.value"
                >{{item.name}}</Radio>
              </RadioGroup>
            </i-col>
            <i-col span="14">
              <Select
                v-model="selectedUserId"
                clearable
                size="small"
                placeholder="选择销售"
                @on-change="handleChangeSaller"
              >
                <Option
                  v-for="item in sellerArray"
                  :key="'seller_'+item.id"
                  :value="item.id"
                >{{item.name}}</Option>
              </Select>
            </i-col>
          </Row>

        </i-col>
      </Row> -->
    <div id="container">
      <div id="map"></div>
      <canvas id="deck-canvas"></canvas>
    </div>
    <Modal
      v-model="showModal"
      width="800"
      :footer-hide="!showSubmitButton"
    >
      <p
        slot="header"
        class="text-center"
      >网格信息</p>
      <Row :gutter="8">
        <i-col span="8"><span class="title">所属站点</span>{{selectedCellInfo.stationNames}}</i-col>
        <i-col span="8"><span class="title">当前销售</span>{{selectedCellInfo.sellName||'暂无'}}</i-col>
        <i-col span="8"></i-col>
        <i-col span="8"><span class="title">商家数量</span>{{selectedCellInfo.businessCount}}</i-col>
        <i-col span="8"><span class="title">已拜访数量</span>{{selectedCellInfo.promoteCount}}</i-col>
        <i-col span="8"><span class="title">签约数量</span>{{selectedCellInfo.signCount}}</i-col>
        <i-col span="24">
          <Table size="small" stripe :data="businessList" :columns="tableColumns"></Table>
          <div class="paging_style">
            <Page size="small" :total="total" :page-size="query.pageSize" show-total show-elevator :current="query.pageNumber"
              @on-change="handlePageChanged"></Page>
          </div>
        </i-col>
      </Row>
      <div slot="footer" >
            <Button
            v-if="!curSellerCellData.some(x=>x.hex===selectedCellInfo.hex)"
            type="success"
            size="small"
            :loading="optLoading"
            @click="handleAdd"
          >确认分配</Button>
          <Button
            v-else
            type="error"
            size="small"
            :loading="optLoading"
            @click="handleRemove"
          >取消分配</Button>
        </div>
    </Modal>
  </div>
</template>
<style lang="less">
#map {
  z-index: 0;
}
#map_search {
  position: relative;
  z-index: 9;
  top: 0px;
  left: 30%;
  width: 1000px;
  .search_option {
    width: 120px;
  }
}
#color-info {
  width: 300px;
  padding: 8px 5px;
  position: fixed;
  border-radius: 3px;
  background: rgba(26, 26, 26, 0.8);
  color: #fff;
  // top: 5px;
  // left: 50%;

  z-index: 9;
  -webkit-box-shadow: 0 0 10px rgba(211, 211, 211, 0.8);
  -moz-box-shadow: 0 0 10px rgba(211, 211, 211, 0.8);
  box-shadow: 0 0 10px rgba(211, 211, 211, 0.8);
  .hex_nd {
    width: 227px;
    margin: 0 auto;
    li {
      float: left;
      list-style: none;
      width: 20%;
    }
  }
  .hex_nd_bj {
    width: 100%;
    height: 20px;
    background: url("~@/assets/images/hex_nd.png") no-repeat;
  }
}
#container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
#container > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>

<script>
import { cloneDeep } from 'lodash'
import { Deck } from '@deck.gl/core'
import { H3HexagonLayer } from '@deck.gl/geo-layers'
import mapboxgl from 'mapbox-gl'
// interface
import { getUserPage } from '@/api/os/user'
import { getCompany } from '@/api/os/company'
import { getCityCell, addUser, deleteUser } from '@/api/sf/cell'
import { allpage, startexportbusiness } from '@/api/sf/business'

import { getCity } from '@/api/sys/provincecityregion'
import { getPublisherAssetList } from '@/api/rim/asset'
import { getStationByAsset } from '@/api/product/station'
import { downloadFileRequest } from '@/utils/download'

var INITIAL_VIEW_STATE = {
  longitude: 120.43,
  latitude: 36.12,
  zoom: 10,
  maxZoom: 20,
  pitch: 30,
  bearing: 0
}

export default {
  name: 'DeckApp',
  data () {
    return {
      deck: {},
      typeArray: [
        { value: 1, name: '商家浓度' },
        { value: 2, name: '地推强度' },
        { value: 3, name: '成交强度' }
      ],
      assetArray: [], // 资产清单
      stationArray: [], // 站点清单
      userTypeArray: [
        { value: 1, name: '小微销售' },
        { value: 0, name: '地推销售' }
      ],
      curType: 1,
      assetId: null,
      stationId: null,
      curUserType: 1,
      userInfo: this.$store.getters.token.userInfo,
      companyInfo: {},
      sellerArray: [],
      // selectedUserId: null,
      curSellerCellData: [], // 当前用户已有网格数据
      otherSellerCellData: [], // 其他用户已有网格数据）
      cityCellData: [], // 接口获取的原始网格数据
      extensionCellData: [], // 地推网格数据
      dealCellData: [], // 成交强度网格数据
      showCityCellData: [], // 用于展示的网格数据
      blankCellData: [], // 空白网格数据
      selectedCellInfo: {}, // 点击选中网格信息
      showModal: false,
      optLoading: false,

      businessList: [],
      tableColumns: [
        { title: '名称', align: 'center', key: 'name' },
        { title: '统一社会信用编码', align: 'center', key: 'orgCode' },
        { title: '行业', align: 'center', key: 'bigTypeName' },
        { title: '联系电话', align: 'center', key: 'tel' },
        { title: '地址', align: 'center', key: 'address' },
        { title: '状态', align: 'center', key: 'statusName' }
      ],
      total: 0,
      query: {
        pageNumber: 1,
        pageSize: 10
      }
    }
  },
  computed: {
    showSubmitButton () {
      if (this.selectedUserId && !this.otherSellerCellData.some(x => x.hex === this.selectedCellInfo.hex)) {
        return true
      } else {
        return false
      }
    },
    selectedUserId () {
      return this.$store.state.microSituation.userId
    }
  },
  mounted () {
    this.initAssetArray()
    this.getSellerData()
    this.getCompanyInfo()
  },
  methods: {
    // 初始化资产选项，并默认选中第一条
    initAssetArray () {
      getPublisherAssetList().then((res) => {
        if (res && !res.errcode) {
          this.assetArray = res
        } else {
          this.assetArray = []
        }
      })
    },
    // 初始化站点选项
    initStationArray () {
      this.stationId = null
      if (!this.assetId) {
        this.stationArray = []
        return false
      }
      getStationByAsset({ assetId: this.assetId }).then((res) => {
        if (res && !res.errcode) {
          this.stationArray = res
        } else {
          this.stationArray = []
        }
      })
    },
    getSellerData (roles = 'ROLE_MINI_SALER') {
      const query = {
        companyId: this.userInfo.companyId,
        roles,
        pageNumber: 1,
        pageSize: 100,
        keyword: ''
      }
      getUserPage(query).then(res => {
        if (res && !res.errcode) {
          this.sellerArray = res.list
        }
      })
    },
    // 获取公司信息
    getCompanyInfo () {
      getCompany({ companyId: this.userInfo.companyId, publisherId: this.userInfo.publisherId }).then(res => {
        if (res && !res.errcode) {
          this.companyInfo = res
        }
      }).then(() => {
        this.getCityInfo()
      })
    },
    getCityInfo () {
      if (this.companyInfo.cityCode) {
        getCity({ cityCode: this.companyInfo.cityCode }).then(res => {
          if (res && !res.errcode) {
            INITIAL_VIEW_STATE.longitude = parseFloat(res.coordinate.split(',')[0])
            INITIAL_VIEW_STATE.latitude = parseFloat(res.coordinate.split(',')[1])
          }
          this.initMap()
        }).then(() => {
          // this.initAssetArray()
          this.getCityCellData()
        })
      }
    },
    // 获取城市网格数据
    getCityCellData () {
      if (this.companyInfo.cityCode) {
        getCityCell({ cityCode: this.companyInfo.cityCode, assetIds: JSON.stringify(this.assetArray.map(x => x.id)) }).then(res => {
          if (res && !res.errcode) {
            res.forEach(item => {
              item.businessCount = item.count
            })
            this.cityCellData = res
            this.showCityCellData = res
            this.blankCellData = this.showCityCellData.filter(x => x.count === 0)
            // 初始化处理网格图层渲染
            this.$nextTick(() => {
              const layers = new H3HexagonLayer([])
              this.deck.setProps({ layers: [layers] })
              this.updateLayer()
            })
          }
        })
      }
    },
    // 切换地图展示类型
    handleChangeType () {
      this.assetId = null
      this.stationId = null

      const tempCellData = cloneDeep(this.cityCellData)
      switch (this.curType) {
        case 1:
          this.showCityCellData = tempCellData
          break
        case 2:
          tempCellData.forEach(item => {
            item.businessCount = item.count
            if (item.count > 0 && item.promoteCount >= 0) {
              item.count = parseFloat(((item.promoteCount / item.count) * 100).toFixed(2))
            } else {
              item.count = 0
            }
          })
          this.extensionCellData = tempCellData
          this.showCityCellData = tempCellData
          break
        case 3:
          tempCellData.forEach(item => {
            item.businessCount = item.count
            if (item.count > 0 && item.signCount >= 0) {
              item.count = parseFloat(((item.signCount / item.count) * 100).toFixed(2))
            } else {
              item.count = 0
            }
          })
          this.dealCellData = tempCellData
          this.showCityCellData = tempCellData
          break
        default:
      }
      this.updateLayer()
    },
    // 更新图层
    updateLayer (highlightIndex = -1) {
      const layers = new H3HexagonLayer({
        id: 'H3HexagonLayer',
        data: this.showCityCellData,

        /* props from H3HexagonLayer class */

        elevationScale: 10,
        extruded: true,
        filled: true,
        stroked: true,
        wireframe: true,
        pickable: true,
        getElevation: 5,
        getFillColor: d => {
          if (this.blankCellData.some(s => s.hex === d.hex)) {
            return [255, 255, 255, 0]
          } else if (this.curSellerCellData.some(x => x.hex === d.hex)) {
            // 当前用户已分配的网格颜色
            return [0, 255, 0]
          } else if (this.otherSellerCellData.some(x => x.hex === d.hex)) {
            // 其他用户已分配的网格颜色
            return [169, 169, 169]
          } else {
            return [255, (1 - d.count / 255) * 255, 0]
            // return [50, 50, (2 - d.count / 125) * 125]
          }
        },
        getLineColor: [169, 169, 169],
        getHexagon: d => d.hex,
        opacity: 0.5,
        // autoHighlight: true,
        highlightColor: [66, 133, 244],
        highlightedObjectIndex: highlightIndex,
        updateTriggers: {
          // 填充颜色实时更新
          getFillColor: d => [255, (1 - d.count / 255) * 255, 0]
        }
      })
      this.deck.setProps({ layers: [layers] })
    },
    // 初始化地图
    initMap () {
      mapboxgl.accessToken = 'pk.eyJ1Ijoib29oZm9yY2UiLCJhIjoiY2tueWJ3MDI2MWV0cjJ2b2F6ajQ2aW56biJ9.hYCo3zTga7fCNZCxP9NcCA'
      // const map = new mapboxgl.Map({
      //   container: 'map', // container id
      //   style: {
      //     version: 8,
      //     sources: {
      //       'raster-tiles': {
      //         type: 'raster',
      //         tiles: ['http://webrd01.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&style=8&x={x}&y={y}&z={z}'],
      //         tileSize: 256
      //       }
      //     },
      //     layers: [{
      //       id: 'simple-tiles',
      //       type: 'raster',
      //       source: 'raster-tiles',
      //       minzoom: 0,
      //       maxzoom: INITIAL_VIEW_STATE.maxZoom
      //     }]
      //   },
      //   center: [INITIAL_VIEW_STATE.longitude, INITIAL_VIEW_STATE.latitude], // starting position
      //   zoom: INITIAL_VIEW_STATE.zoom,
      //   minZoom: 5,
      //   bearing: INITIAL_VIEW_STATE.bearing,
      //   pitch: INITIAL_VIEW_STATE.pitch
      // })
      var map = new mapboxgl.Map({
        container: 'map', // container id
        style: {
          version: 8,
          name: 'Mapbox Streets',
          sprite: 'mapbox://sprites/mapbox/streets-v8',
          glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
          sources: {
            'osm-tiles': {
              type: 'raster',
              tiles: [
                'https://webrd01.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&style=8&x={x}&y={y}&z={z}'
              ],
              tileSize: 256
            }
          },
          layers: [{
            id: 'mapbox-gl',
            type: 'raster',
            source: 'osm-tiles',
            'source-layer': 'osmtiles'
          }]
        },
        center: [INITIAL_VIEW_STATE.longitude, INITIAL_VIEW_STATE.latitude], // starting position [lng, lat]
        zoom: 10 // starting zoom
      })

      this.deck = new Deck({
        canvas: 'deck-canvas',
        // width: '100%',
        // height: '100%',
        initialViewState: INITIAL_VIEW_STATE,
        controller: true,
        onViewStateChange: ({ viewState }) => {
          map.jumpTo({
            center: [viewState.longitude, viewState.latitude],
            zoom: viewState.zoom,
            bearing: viewState.bearing,
            pitch: viewState.pitch
          })
        },
        getCursor: ({ isHovering }) => isHovering ? 'pointer' : 'grabbing',
        getTooltip: ({ object }) => object && {
          html: `<p>所属站点：${object.stationNames}</p><p>商家数量：${object.businessCount}</p><p>已拜访数量：${object.visitCount}</p><p>签约数量：${object.signCount}</p><p>当前销售：${object.sellName || '暂无'}</p>`,
          style: {
            width: 'auto',
            height: 'auto',
            padding: '10px 10px',
            background: 'rgba(255, 255, 255, 0.9)'
          }
        },
        onClick: (info) => {
          this.handleClickCell(info)
        },
        layers: []
      })
    },
    handleChangeAsset () {
      // 获取线路站点数据
      this.initStationArray()
      // 筛选当前线路的网格数据
      let tempCellData = []
      switch (this.curType) {
        case 1:
          tempCellData = cloneDeep(this.cityCellData)
          break
        case 2:
          tempCellData = this.extensionCellData
          break
        case 3:
          tempCellData = this.dealCellData
          break
        default:
          tempCellData = cloneDeep(this.cityCellData)
          break
      }

      if (this.assetId) {
        this.showCityCellData = tempCellData.filter(x => x.assetId === this.assetId)
      } else {
        this.stationId = null
        this.showCityCellData = tempCellData
      }
      // 初始化处理网格图层渲染
      this.updateLayer()
    },
    handleChangeStation () {
      if (!this.assetId) {
        return false
      }
      // 筛选当前线路的网格数据
      let tempCellData = []
      switch (this.curType) {
        case 1:
          tempCellData = cloneDeep(this.cityCellData)
          break
        case 2:
          tempCellData = this.extensionCellData
          break
        case 3:
          tempCellData = this.dealCellData
          break
        default:
          tempCellData = cloneDeep(this.cityCellData)
          break
      }
      if (this.stationId) {
        this.showCityCellData = tempCellData.filter(x => x.stationIds.includes(this.stationId))
      } else {
        // 筛选当前线路的网格数据
        this.showCityCellData = tempCellData.filter(x => x.assetId === this.assetId)
      }

      // 初始化处理网格图层渲染
      this.updateLayer()
    },
    handleClickCell (info) {
      if (info && info.index > -1) {
        this.selectedCellInfo = info.object || {}
        this.initBusinessList()
        this.showModal = true
      }
      this.updateLayer(info.index)
    },
    // 加载小微商家
    initBusinessList () {
      const that = this
      that.query.cellId = this.selectedCellInfo.hex

      allpage(that.query).then(res => {
        that.businessList = res.list
        that.total = res.totalRow
      })
    },
    handlePageChanged (page) {
      this.query.pageNumber = page
      this.initBusinessList()
    },
    handleAdd () {
      // 只能添加可用网格
      if (this.otherSellerCellData.some(x => x.hex === this.selectedCellInfo.hex)) {
        this.$Notice.error({ desc: '只能分配可用网格' })
        return false
      }
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定将该区域分配给当前销售？',
        onOk: () => {
          this.optLoading = true
          const params = {
            cityCode: this.companyInfo.cityCode,
            publisherId: this.userInfo.publisherId,
            type: this.curUserType,
            userId: this.selectedUserId,
            cellId: this.selectedCellInfo.hex
          }
          addUser(params).then(res => {
            this.optLoading = false
            if (res && res.errcode === 0) {
              // 更改当前操作成功的原始数据
              const cellInfo = this.cityCellData.find(x => x.hex === this.selectedCellInfo.hex)
              switch (this.curUserType) {
                case 0:
                  cellInfo.promoteUserId = params.userId
                  break
                case 1:
                  cellInfo.sellerId = params.userId
              }
              this.curSellerCellData.push(cellInfo)
              this.showModal = false
              this.$Notice.success({ desc: '操作成功' })
              this.updateLayer()
            }
          })
        }
      })
    },
    handleRemove () {
      // 只能删除当前用户已有网格
      if (!this.curSellerCellData.some(x => x.hex === this.selectedCellInfo.hex)) {
        this.$Notice.error({ desc: '只能删除当前用户已有网格' })
        return false
      }
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要将该区域从当前销售移除？',
        onOk: () => {
          this.optLoading = true
          const params = {
            cityCode: this.companyInfo.cityCode,
            publisherId: this.userInfo.publisherId,
            type: this.curUserType,
            userId: this.selectedUserId,
            cellId: this.selectedCellInfo.hex
          }
          deleteUser(params).then(res => {
            this.optLoading = false
            if (res && res.errcode === 0) {
              // 更改当前操作成功的原始数据
              const cellInfo = this.cityCellData.find(x => x.hex === this.selectedCellInfo.hex)
              switch (this.curUserType) {
                case 0:
                  cellInfo.promoteUserId = -1
                  break
                case 1:
                  cellInfo.sellerId = -1
              }
              this.curSellerCellData.splice(this.curSellerCellData.findIndex(x => x.hex === this.selectedCellInfo.hex), 1)
              this.showModal = false
              this.$Notice.success({ desc: '操作成功' })
              this.updateLayer()
            }
          })
        }
      })
    },
    confirmDownload () {
      this.sign = ''
      this.$Spin.show({
        render: (h) => {
          return h('div', [
            h('Icon', {
              class: 'spin-icon-load',
              props: {
                type: 'ios-loading',
                size: 24
              }
            }),
            h('div', '数据生成中...')
          ])
        }
      })
      const timer = setInterval(() => {
        const query = {
          publisherId: this.userInfo.publisherId, sign: this.sign
        }
        if (this.stationId) {
          query.stationId = this.stationId
        }
        if (this.assetId) {
          query.assetId = this.assetId
        }
        startexportbusiness(query).then(res => {
          if (res && !res.errcode) {
            this.sign = res.sign
            if (res.status === 2) {
              this.$Spin.hide()
              clearInterval(timer)

              const filename = '商家列表.xlsx'

              downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-sf/business/downloadexcel', { sign: this.sign }, filename)
            }
          } else {
            this.$Spin.hide()
            clearInterval(timer)
          }
        }).catch(() => {
          this.$Spin.hide()
          clearInterval(timer)
          this.$Notice.error({ desc: '发生一个异常' })
        })
      }, 3000)
    }
  },
  watch: {
    selectedUserId (val) {
      this.curSellerCellData = this.selectedUserId ? this.cityCellData.filter(x => x.sellerId === this.selectedUserId) : []
      this.otherSellerCellData = this.selectedUserId ? this.cityCellData.filter(x => x.sellerId !== this.selectedUserId && x.sellerId > 0) : []
      this.updateLayer()
    }
  }
}
</script>
